export default {
  //MEDIA_ROOT_URL: "https://schoolroomhelp.20thfloor.us/",
  MEDIA_ROOT_URL: "https://schoolroomhelp.net/",
 	// MEDIA_ROOT_URL: "https://schoolroomhelp.com/",
 	// MEDIA_ROOT_URL: "http://localhost:8000/",

 	//20thfloor paypal

 	//PayPal_key : "AfZ2PrH95E8LeVnVkfMSgTI15l19ND-tm66CUbT_zWokpJvGLKL9WdHvYVeH3rJo8Wb0eJnhsZEKbBzz",


 	//SRH paypal
 	PayPal_key : "AelTE5zCyGHWtdR9xOEbJ_hCoRzUdV6kkIpbv15vJXMZdyX8mgoxQd8E7c6LkqBxzxkqGe_oJ8QN05x3",
};
