module.exports = {
  // base_url: 'https://schoolroomhelp.20thfloor.com/api',
  // home_url:'https://schoolroomhelp.20thfloor.com',
  // login_url: 'https://auth.20thfloor.com',
  // logout_url: 'https://auth.20thfloor.com/authorize?logout=true&role=sadmin',
  chat_url:'https://chat.schoolroomhelp.net',

 // base_url: 'http://localhost:8000/api',
  //home_url:'http://localhost:8000',
  //login_url: 'http://localhost:3000',
  //logout_url: 'http://localhost:3000/authorize?logout=true&role=sadmin',


  base_url: "https://schoolroomhelp.net/api",
  home_url: "https://schoolroomhelp.net",
  login_url: "https://auth.schoolroomhelp.net",
  logout_url: "https://auth.schoolroomhelp.net/authorize?logout=true&role=sadmin",
  //chat_url:'https://chat.schoolroomhelp.com',
};
